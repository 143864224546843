.blur-block {
  filter: blur(5px);
  position: relative;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */ }

.position-rel {
  position: relative; }

.sticks-var {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px 35px;
  position: absolute;
  z-index: 3;
  top: 180px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FFFFFF;
  border: 2px solid #B0B0B0;
  width: 470px;
  height: 250px;
  font-size: 15px;
  line-height: 1.3;
  color: #3a3a3a; }
  @media screen and (min-width: 1800px) {
    .sticks-var {
      font-size: 18px; } }
  @media screen and (min-width: 1200px) {
    .sticks-var {
      top: 140px; } }
  @media screen and (max-width: 768px) {
    .sticks-var {
      top: 180px;
      padding: 10px 15px;
      max-width: 350px; } }
  .sticks-var .text-small {
    font-size: 11px;
    line-height: 1;
    margin-top: 40px; }
  .sticks-var .sticks-text {
    margin-bottom: 40px; }
    @media screen and (max-width: 768px) {
      .sticks-var .sticks-text {
        margin-bottom: 20px; } }

.authorization-btn:hover,
.authorization-btn {
  width: 345px;
  height: 53px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #FFFFFF;
  font-family: e-Ukraine, sans-serif;
  text-transform: capitalize; }
